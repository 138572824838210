import { Span, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";

export function TouchpointContinueAnywayDialog(props: {
  modalState: UseModalState;
  facilityName: string;
  clockAction: "clock-in" | "clock-out";
  onCancel: () => void;
  onContinue: () => void;
}) {
  const { modalState, facilityName, clockAction, onCancel, onContinue } = props;

  return (
    <CommonDialog
      disableActionsSpacing
      modalState={modalState}
      scroll="body"
      actions={
        <>
          <Button fullWidth color="primary" variant="contained" onClick={onCancel}>
            Go back
          </Button>
          <Button fullWidth variant="outlined" onClick={onContinue}>
            Clock {clockAction === "clock-in" ? "in" : "out"}
          </Button>
        </>
      }
      title="Possible penalities"
      onClose={() => {
        onCancel();
      }}
    >
      <Stack spacing={2} alignItems="center">
        <Text>
          {facilityName} requires professionals to clock in/out at the kiosk.{" "}
          <Span color="red">
            Failure to do so may result in penalities like DNR or payment reversals.
          </Span>
        </Text>
        <Text>
          Proceed with clock {clockAction === "clock-in" ? "in" : "out"} only if you are certain
          that the kiosk is not working.
        </Text>
      </Stack>
    </CommonDialog>
  );
}
