export interface TouchpointCodeGeneratorProps {
  agencyName: string;
  agencyId: number;
  shiftId: string;
  agentId: string;
  agentType?: string;
  agentFirstName: string;
  agentLastName: string;
  facilityId: string;
  facilityName?: string;
  punchType: "clock-in" | "clock-out";
  buttonTapTime?: Date;
}

const TOUCHPOINT_SEPARATOR = "|";
const TOUCHPOINT_SEPARATOR_STRIP_REGEX = /\|/g;

/**
 * The spec is described in this document:
 * https://drive.google.com/file/d/1KKiSaECYB5JYEUUeZG0kPCKHUqJLMWRh/view
 * Additional implementation details have been discussed with
 * the TouchPoint team and explained in the tickets:
 * - https://linear.app/clipboardhealth/issue/TX-1898/build-qr-code-ux
 * - https://linear.app/clipboardhealth/issue/TX-1925/fix-qr-code-bugs
 */
export function touchpointCodeGenerator(props: TouchpointCodeGeneratorProps): string {
  const {
    agencyName,
    agencyId,
    shiftId,
    agentId,
    facilityId,
    facilityName,
    agentFirstName,
    agentLastName,
    agentType,
    punchType,
    buttonTapTime,
  } = props;

  const dateNow = buttonTapTime ?? new Date();

  const utcTime = `${dateNow.getUTCHours()}:${dateNow.getUTCMinutes()}`;
  const utcDate = `${
    dateNow.getUTCMonth() + 1
  }/${dateNow.getUTCDate()}/${dateNow.getUTCFullYear()}`;

  const punchCode = punchType === "clock-in" ? 1 : 2;

  return [
    // Agency Name (Clipboard)
    agencyName,
    // Agency ID (provided by TouchPoint)
    agencyId,
    // Agent First Name
    agentFirstName.replace(TOUCHPOINT_SEPARATOR_STRIP_REGEX, ""),
    // Agent Last Name
    agentLastName.replace(TOUCHPOINT_SEPARATOR_STRIP_REGEX, ""),
    // Agent ID (our database ID)
    agentId.replace(TOUCHPOINT_SEPARATOR_STRIP_REGEX, ""),
    // Shift ID (our database ID)
    shiftId.replace(TOUCHPOINT_SEPARATOR_STRIP_REGEX, ""),
    // Facility Name
    facilityName?.replace(TOUCHPOINT_SEPARATOR_STRIP_REGEX, "") ?? "",
    // Facility ID (our database ID)
    facilityId.replace(TOUCHPOINT_SEPARATOR_STRIP_REGEX, ""),
    // Agent Type (RN, LPN, CNA, etc.)
    agentType?.replace(TOUCHPOINT_SEPARATOR_STRIP_REGEX, "") ?? "",
    // Pricing rate (not used for us)
    "0.0",
    // UTC Time (HH:MM)
    utcTime,
    // UTC Date (MM/DD/YYYY)
    utcDate,
    // Punch Type (1 = Clock-In, 2 = Clock-Out)
    punchCode,
    // Phone Number (dummy)
    "1111111111",
  ].join(TOUCHPOINT_SEPARATOR);
}
