import { isDefined } from "@clipboard-health/util-ts";
import { isCapacitorPlatform, useAppInfo } from "@src/appV2/lib/index";
import { type TouchpointIntegrationConfig } from "@src/appV2/Shifts/Shift/ShiftState/types";

import { isEnabledForAppVersionAndOta } from "../../utils/isSolveUnpaidBreaksFeatureEnabled";

export interface TouchpointIntegrationConfigEnabledValue {
  kioskLocation: string;
  agencyId: number;
  agencyName: string;
  isWebhookOn: boolean;
}

export function useIsTouchpointIntegrationEnabled(props: {
  integrationConfig?: TouchpointIntegrationConfig;
}): TouchpointIntegrationConfigEnabledValue | undefined {
  const { integrationConfig } = props;
  const { appInfo } = useAppInfo();

  if (!isDefined(integrationConfig)) {
    return undefined;
  }

  const { asAgencyId, asAgencyName, kioskLocation, isWebhookOn, minVersion } = integrationConfig;

  if (
    isCapacitorPlatform() &&
    minVersion &&
    appInfo &&
    !isEnabledForAppVersionAndOta({
      minimalOtaVersion: minVersion.ota,
      minimalStoreVersion: minVersion.store,
      appInfo,
    })
  ) {
    return undefined;
  }

  return {
    kioskLocation,
    agencyId: asAgencyId,
    agencyName: asAgencyName,
    isWebhookOn: isWebhookOn ?? false,
  };
}
